import { memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { makeStyles, createStyles } from '@mui/styles'

import Header from '../header/Header'
import Footer from '../footer/Footer'
import TrustCards from '../trust-cards'
import LoanSelectorHeader from '../loan-selector/loan-selector-header'
import BankLogo from '../loan-selector/bank-logo'
import LoadingSkeleton from './LoadingSkeleton'

import { LoanSelectionProvider } from '../../contexts/LoanSelectorContext'

import { initPollardCommunication } from '../../utils/pollard'
import { inIframe } from '../../utils/window/iframe'

const LoanSelector = dynamic(() => import('../loan-selector'), { ssr: false })

const useStyles = makeStyles(() =>
  createStyles({
    app: {
      display: 'flex',
      flexDirection: 'column',
      height: inIframe() ? 'auto' : '100vh',
    },
    content: {
      flexGrow: 1,
      margin: 'auto',
      maxWidth: '532px',
      padding: '0 15px',
      width: '100%',
    },
  }),
)

interface PageProps {
  hasHeader?: boolean
  hasEkomi?: boolean
  hasSchufaNeutral?: boolean
  hasTuev?: boolean
  extendedFooter?: boolean
}

const Page = ({
  hasHeader = true,
  hasEkomi = true,
  hasSchufaNeutral = true,
  hasTuev = true,
  extendedFooter = true,
}: PageProps) => {
  const { app, content } = useStyles()

  const { query } = useRouter()
  const { bankpartner, productId } = query

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
      // Listen to the changeViewPort again and
      // send the height of iframe after rendering the actual content
      initPollardCommunication()
    }, 1500)
  }, [isLoaded])

  return (
    <div className={app}>
      {hasHeader && <Header />}
      <div className={content}>
        {isLoaded ? (
          <>
            {bankpartner || productId ? (
              <BankLogo
                productId={productId as string}
                bankName={bankpartner as string}
              />
            ) : (
              <LoanSelectorHeader />
            )}
            <LoanSelectionProvider>
              <LoanSelector />
            </LoanSelectionProvider>
            <TrustCards
              hasEkomi={hasEkomi as boolean}
              hasGuenstigerGehtNicht={false}
              hasSchufaNeutral={hasSchufaNeutral as boolean}
              hasTuev={hasTuev as boolean}
            />
          </>
        ) : (
          <LoadingSkeleton />
        )}
      </div>
      <Footer extended={!!extendedFooter} />
    </div>
  )
}

export default memo(Page)
